/* @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    src: url('../src/fonts/NotoSansJP-Medium.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-Black.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-Bold.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-ExtraBold.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-Light.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-Regular.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-Thin.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-SemiBold.ttf') format('truetype'),
        url('../src/fonts/NotoSansJP-ExtraLight.ttf') format('truetype');
} */
@import './assets/css/calendar.css';
@keyframes fadeinout {
    0% {
        opacity: 1;
    }
    5% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeout {
    0% {
        background-color: #ebfae8;
    }
    75% {
        background-color: #ebfae8;
    }
    100% {
        background-color: #fff;
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

:root{
    --ck-font-size-base: 15px;
    --ck-color-focus-border: #666;
    --ck-color-panel-border: #666;
    --ck-color-toolbar-border: #666;
    --ck-color-base-border: #666;
}

.ck-body-wrapper {
    display: none;
}

.ck.ck-toolbar {
    box-shadow: none !important;
    border-width: 0px 0px 1px 0px !important;
}
.ck.ck-toolbar {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px #fff inset,0 0 !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    white-space: break-spaces;
}

.ck-reset_all :not(.ck-reset_all-excluded *) {
    font-family: 'Noto Sans JP', sans-serif;
    color: #000000;
}
.ck.ck-content p {
    margin-bottom: 0px;
}

.scroll-hint-icon {
    height: 120px !important;
    width: 120px !important;
    background-image: url('./assets/img/scroll-hint.png') !important;
    background-size: cover !important;
    top: calc(50% - 50px) !important;
    padding: 52px 34px 20px !important;
    background-color: initial !important;
}
.scroll-hint-icon::before {
    background-image: url('./assets/img/scroll-hint-icon.png') !important;
}
.scroll-hint-icon::after {
    content: none !important;
}

.scroll-hint-text {
    display: none !important;
}