.CalendarDay__today {
    color: #3290ee;
    font-weight: 700;
}

.CalendarDay__selected_span {
    background: #ebf4f8;
    color: #000000;
    border: 1px double #3290ee;
}

.CalendarDay__selected {
    background: #3290ee;
    color: white;
    border: 1px double #3290ee;
    font-weight: 400 !important;
}
  
.CalendarDay__selected:hover {
    background: rgb(161, 161, 161);
    color: white;
    border: 1px double #e4e7e7;
}

.CalendarDay__selected_span:hover {
    background: rgb(161, 161, 161);
    color: white;
    border: 1px double #3290ee;
}

.CalendarDay__hovered_span {
    background: #dbecff;
    color: #000000;
    border: 1px double #3290ee;
}

.CalendarDay__hovered_span:hover {
    background: #3290ee;
    color: #FFF;
    border: 1px double #3290ee;
}


.CalendarDay .CalendarDay__default {
    font-size: 13px;
}

.CalendarMonth_caption {
    font-size: 16px;
}

.DayPicker_weekHeader_li > small {
    font-size: 13px;
    font-weight: 500;
}

.DateInput_input {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    background: transparent;
    width: 100%;
}

.DateRangePickerInput {
    white-space: nowrap;
    width: 100%;
    text-align: center;
    border: none;
    background: transparent;
}

.DateInput_input__focused {
    border-bottom: none;
}

.DateRangePicker {
    width: 100%;
    background: transparent;
}

.DateInput {
    width: 48%;
    background: transparent;
}

.DateRangePickerInput_arrow  {
    width: 4%;
}

@media only screen and (max-width: 600px) {
    .DateRangePicker_picker {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      z-index: 9999;
      overflow-y: scroll;
    }

    .DayPickerNavigation__vertical {
        height: 32px;
    }

    .DayPickerNavigation_svg__vertical {
        height: 24px;
    }

    .DayPickerNavigation_button {
        display: none;
    }

    .DayPickerNavigation {
        display: none;
    }

    .DayPickerCustomBtn {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
    }

    .DayPickerCustomBtn_submit {
        color: white !important;
        font-weight: 600 !important;
        font-size: 1rem !important;
        background-color: #1668e3 !important;
        width: calc(100% - 40px);
        border-radius: 2500rem !important;
        height: 48px;
    }

    .DateRangePicker_closeButton {
        position: fixed;
        color: #1668e3 !important;
    }

    .CalendarMonthGrid_month__hidden_1 {
        visibility: initial;
    }

    .CalendarMonthGrid {
        margin-bottom: 120px;
        margin-top: 50px;
    }

    .DayPicker_weekHeader {
        position: fixed;
        top: 0px;
        background: white;
        border-bottom: 1px solid #dddddd;
        padding-top: 16px !important;
    }

    .CalendarMonth_caption {
        padding-bottom: 10px;
        padding-top: 0px;
    }
}